@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;700&display=swap");

.event-main-div {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: 40px;
  height: auto;

  .add-event {
    padding: 20px;
    width: 500px;
    margin-top: 50px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: "Roboto", sans-serif;

    .title-event {
      text-align: left;
      margin-bottom: 20px;
      font-family: "Kumbh Sans", sans-serif;
      font-weight: 600;
      color: #333;
      font-size: 35px;
    }

    form {
      display: flex;
      flex-direction: column;

      div {
        margin-bottom: 20px;

        label {
          display: block;
          margin-bottom: 10px;
          font-weight: 500;
          color: #333;
          font-family: "Kumbh Sans", sans-serif;
        }

        .input-event {
          width: 100%;
          padding: 10px;
          font-size: 16px;
          border: 1px solid #ddd;
          border-radius: 4px;

          &::placeholder {
            color: #c9bfbf;
            font-size: 14px;
            font-family: "Kumbh Sans", sans-serif;
          }
        }

        input[type="file"] {
          border: none;
          padding: 0;
          font-size: 14px;
        }
      }

      .button-add-event {
        width: 100%;
        padding: 10px 20px;
        font-size: 16px;
        color: #fff;
        background-color: #00a5a2;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #008d8b;
        }
      }
    }
  }

  .events-container {
    flex-grow: 1;
    margin-top: 50px;
    margin-left: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    max-height: 700px;
    overflow-y: auto;

    .title-event-result {
      text-align: left;
      margin-bottom: 20px;
      font-family: "Kumbh Sans", sans-serif;
      font-weight: 600;
      color: #333;
      font-size: 35px;
      grid-column: span 2;
    }

    .event-card {
      background: #f9f9f9;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .event-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;

        .event-image {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 15px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        .event-heading {
          font-weight: 700;
          color: #333;
          font-size: 18px;
          margin-bottom: 10px;
        }

        .event-description {
          font-size: 14px;
          color: #666;
          margin-bottom: 10px;
        }

        .event-date-time {
          font-size: 14px;
          color: #999;
        }
      }

      .event-actions {
        display: flex;
        gap: 10px;

        button {
          padding: 5px 10px;
          font-size: 14px;
          color: #fff;
          background-color: #00a5a2;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: #008d8b;
          }
        }
      }
    }
  }
}
