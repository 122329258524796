@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');


.login-container {
  
 
  max-width: 400px;
  margin: 150px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;

 
 

  .main-text {
    font-size: 24px;
    font-family: "Kumbh Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "YOPQ" 300;
    color: #333335;
  }

  .sub-main-text {
    margin-top: -30px;
    font-size: 14px;
    font-family: "Kumbh Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    color: #333335;
  }

  form {
    padding: 20px;
    text-align: left;

    .username-main-div,
    .password-main-div {
      margin-bottom: 20px;

      .label-name,
      .label-password {
        display: block;
        margin-bottom: 8px;
        color: black;
        font-size: 14px;
        font-family: "Kumbh Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;

        .required-asterisk {
          color: red;
        }
      }

      .main-input {
        width: calc(100% - 16px);
        padding: 8px;
        border: 1px solid #e9edf6;
        border-radius: 4px;
        position: relative;
        height: 14px;
      }
    }

    .password-input-container {
      position: relative;

      .toggle-password {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #333;
      }
    }

    .additional-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .remember-me {
        display: flex;
        align-items: center;

        input {
          margin-right: 5px;
        }

        label {
          font-family: "Kumbh Sans", sans-serif;
          font-size: 14px;
          color: #333335;
        }
      }

      .forgot-password {
        a {
          font-family: "Kumbh Sans", sans-serif;
          font-size: 14px;
          color: #333335;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }

    button {
      width: 100%;
      padding: 10px;
      background-color: rgb(0,165,162);
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-family: "Kumbh Sans", sans-serif;
      font-weight: bold;
    }

    button:hover {
      background-color: #45a049;
    }
  }

  .signup-text {
    margin-top: 5px;
    margin-bottom: 40px;
    font-family: "Kumbh Sans", sans-serif;
    font-size: 14px;
    color: #333335;

    a {
      color: #007bff;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .error-message {
    color: red;
    margin-top: 10px;
  }
}
