@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.main-panel {
  background-color: rgb(229, 220, 220);
}
.admin-panel {
  display: flex;
}

.content {
  margin-left: 200px;
  padding: 20px;
  width: calc(100% - 200px); /* Adjust the width to account for the sidebar */
  position: relative;
  transition: margin-left 0.3s ease, width 0.3s ease;
}

.sticky-navbar {
  position: fixed;
  top: 0;
  left: 260px; /* Adjust according to your sidebar width */
  right: 0;
  background-color: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: left 0.3s ease, width 0.3s ease;
}

.left-section {
  display: flex;
  align-items: center;
}

.toggle-sidebar {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-right: 20px;
}

.search-box {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
}

.search-box input {
  border: none;
  outline: none;
  margin-left: 10px;
}

.right-section {
  display: flex;
  align-items: center;
}

.welcome-text {
  font-size: 16px;
  margin-right: 20px;
}

.icon {
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.user-profile {
  position: relative;
}

.user-profile .dropdown {
  display: none;
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 1001;
}

.user-profile:hover .dropdown {
  display: block;
}

.user-profile .dropdown button {
  background: none;
  border: none;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.user-profile .dropdown button:hover {
  background-color: #f9f9f9;
}

.sidebar.collapsed {
  width: 50px;
}

.sidebar.collapsed + .content {
  margin-left: 50px;
  width: calc(100% - 50px); /* Adjust the width to account for the collapsed sidebar */
}

.sidebar.collapsed + .content .sticky-navbar {
  left: 90px; /* Adjust the left position to account for the collapsed sidebar */
}

.logo-div {
  margin-left: 0px;
  height: 40px;
  margin-right: 30px;
}

.logo-img {
  height: 100%;
  width: 100%;
}