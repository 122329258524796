.add-job-container {
  background-color: #ffffff; // White background
  color: #000000; // Black text
  padding: 30px;
  border: 1px solid #dcdcdc; // Grey border
  border-radius: 10px;
  width: 94%;
  margin-top: 46px;
  margin-left: 32px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  display: flex;
  flex-direction: row;
  gap: 20px;

  form {
    display: flex;
    flex-direction: column;
    width: 40%;
    background-color: #f5f5f5; // Light grey form background
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #dcdcdc; // Grey border
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow

    input[type="text"],
    input[type="number"],
    textarea {
      background-color: #f9f9f9; // Slightly lighter input background
      color: #000000; // Black text
      border: 1px solid #dcdcdc; // Grey border
      border-radius: 5px;
      padding: 12px;
      margin-bottom: 15px;
      font-size: 1em;
      resize: vertical;
      outline: none;

      &:focus {
        border-color: #007bff; // Blue border on focus
      }
    }

    button {
      background-color: #007bff; // Blue button background
      color: #ffffff; // White text
      border: 2px solid #007bff;
      padding: 12px 24px;
      border-radius: 5px;
      font-weight: bold;
      cursor: pointer;
      margin-top: 10px;
      transition: background-color 0.3s ease, color 0.3s ease;

      &:hover {
        background-color: #0056b3; // Darker blue on hover
        color: #ffffff;
      }
    }
  }

  .job-list {
    width: 60%;
    max-height: 800px;
    overflow-y: auto;
    padding: 20px;
    background-color: #f5f5f5; // Light grey background for job list
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #dcdcdc;

    .job-item {
      background-color: #ffffff; // White background for job items
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 20px;
      border: 1px solid #dcdcdc;
      transition: transform 0.2s ease;

      &:hover {
        transform: translateY(-5px); // Slight lift on hover
      }

      h3 {
        color: #007bff; // Blue for job titles
        margin-bottom: 10px;
        font-size: 1.2em;
      }

      p {
        margin: 5px 0;
        line-height: 1.6;
      }

      button {
        background-color: #007bff; // Blue button background within job items
        color: #ffffff; // White text
        border: 1px solid #007bff;
        padding: 8px 16px;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 10px;
        margin-right: 20px;

        &:hover {
          background-color: #0056b3; // Darker blue on hover
          color: #ffffff;
        }
      }
    }
  }
}
