@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;700&display=swap');

.category-main-div {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  margin-left: 40px;
  height: 620px;
}

.add-album-category {

  padding: 20px;
  width: 45%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  height: 290px;
  margin-top: 50px;
}

.category-list {
  padding: 20px;
  width: 45%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  height: 540px; /* Set a fixed height for the right card */
  overflow-y: auto; /* Enable vertical scrolling */
}

.add-album-category h2 {
  text-align: center;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 600;
  color: #333;
  font-size: 35px;
}

.add-album-category form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-album-category input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  width: 80%;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: 'Kumbh Sans', sans-serif;
}

.add-album-category button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #00A5A2;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Kumbh Sans', sans-serif;

  &:hover {
    background-color: #008d8b;
  }
}

.category-list h2 {
  text-align: left;
  margin-bottom: 20px;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 600;
  color: #333;
  font-size: 25px;
}

.category-list ul {
  list-style-type: none;
  padding: 0;
}

.category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Kumbh Sans', sans-serif;
}

.category-item span {
  flex: 1;
}

.category-actions {
  display: flex;
  gap: 10px;
}

.edit-button, .delete-button {
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Kumbh Sans', sans-serif;
}

.edit-button {
  background-color: #00A5A2;

  &:hover {
    background-color: #008d8b;
  }
}

.delete-button {
  background-color: #00A5A2;

  &:hover {
    background-color: #008d8b;
  }
}
