@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;700&display=swap');

.album-main-div {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}

.add-album {
  padding: 20px;
  width: 45%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  height: 320px;
  margin-top: 50px;
  margin-left: 20px;
}

.albums-list {
  padding: 20px;
  width: 45%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  height: 540px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.add-album h2 {
  text-align: center;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 600;
  color: #333;
  font-size: 35px;
}

.add-album form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-album select,
.add-album input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  width: 80%;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: 'Kumbh Sans', sans-serif;
}

.add-album button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Kumbh Sans', sans-serif;

  &:hover {
    background-color: #0056b3;
  }
}

.albums-list {
  margin-top: 50px;
}

.albums-list h2 {
  text-align: left;
  margin-bottom: 20px;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 600;
  color: #333;
  font-size: 25px;
  grid-column: span 2;
}

.album-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 300px; /* Fixed height for the album card */
  width: 200px; /* Fixed width for the album card */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ;
}

.album-image {
  width: 100%;
  height: 150px; /* Fixed height for the image */
  object-fit: cover; /* Ensure the image covers the area */
}

.album-card-text {
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.album-card-text h4 {
  margin: 10px 0 5px;
  font-size: 18px;
  font-family: 'Kumbh Sans', sans-serif;
}

.album-card-text button {
  margin: 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Kumbh Sans', sans-serif;
}

.edit-button {
  background-color: #007bff;

  &:hover {
    background-color: #0056b3;
  }
}

.delete-button {
  background-color: #ff4d4d;

  &:hover {
    background-color: #d42f2f;
  }
}
