@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;700&display=swap');

.category-main-div {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  margin-left: 40px;
  height: 620px;
}

.add-category {
  padding: 20px;
  width: 45%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  height: 280px;
}

.category-list {

  padding: 20px;
  width: 45%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  height: 540px; /* Set a fixed height for the right card */
  overflow-y: auto; /* Enable vertical scrolling */
}

.add-category {
  margin-top: 50px;

  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    div {
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 10px;
        font-weight: 700;
        color: #333;
        font-family: "Kumbh Sans", sans-serif;
        font-weight: 500;
      }

      input {
        width: 96%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }

      input::placeholder {
        color: #c9bfbf;
        font-size: 14px;
        font-family: "Kumbh Sans", sans-serif;
      }
    }

    .category-title {
      text-align: left;
      margin-bottom: 20px;
      font-family: "Kumbh Sans", sans-serif;
      font-weight: 600;
      color: #333;
      font-size: 35px;
    }

    button {
      width: 150px;
      padding: 10px 20px;
      font-size: 16px;
      color: #fff;
      background-color: #00A5A2;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.category-list {
  margin-top: 50px;

  h2 {
    text-align: left;
    margin-bottom: 20px;
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 600;
    color: #333;
    font-size: 25px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      background: #f9f9f9;
      padding: 10px;
      border-radius: 5px;

      span {
        flex: 1;
      }

      button {
        margin-left: 5px;
        padding: 5px 10px;
        background-color: #00A5A2;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}
